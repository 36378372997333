import React from 'react';
import moment from 'moment';

import './tableRankingTitle.scss';
import { Row, Col } from 'react-flexbox-grid';

const TableRankingTitle = () => {
  const today = moment();
  const maxPR = moment('2025-07-20', 'YYYY-MM-DD');
  const showPR = today < maxPR;

  const renderHeader = () => {
    return (
      <Col xs={12} className='table-ranking'>
        <Row className='table-ranking_title-container hide-desktop'>
          <Col xs={2} className='table-ranking_title'>
            POS
          </Col>
          <Col xs={6} className='table-ranking_title name'>
            NOMBRE
          </Col>
          {showPR ? (
            <Col xs={4} className='table-ranking_title'>
              PR
            </Col>
          ) : (
            <Col xs={4} className='table-ranking_title'>
              CC2024
            </Col>
          )}
        </Row>
        <Row className='table-ranking_title-container hide-mobile'>
          <Col xs={2} className='table-ranking_title'>
            POSICIÓN
          </Col>
          <Col xs={6} className='table-ranking_title name'>
            NOMBRE
          </Col>
          {showPR ? (
            <Col xs={4} className='table-ranking_title'>
              PR ACTUAL
            </Col>
          ) : (
            <Col xs={4} className='table-ranking_title'>
              COLOMBIA CHALLENGE 2024
            </Col>
          )}
        </Row>
      </Col>
    );
  };
  return renderHeader();
};

export default TableRankingTitle;
