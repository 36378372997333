import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import config from '../../config';
import Footer from '../../components/Footer';
import Header from '../Header';
import './payment-success.scss';

const paymentStatusMapping = {
  APPROVED: 'PAGO APROBADO',
  PENDING: 'PAGO PENDIENTE',
  DECLINED: 'PAGO RECHAZADO',
  VOIDED: 'PAGO ANULADO',
  ERROR: 'ERROR EN EL PAGO',
  DEFAULT: 'ESTADO DESCONOCIDO',
};

const PaymentSuccessPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const queryParams = new URLSearchParams(location.search);
  const transactionReference = queryParams.get('id');
  const transactionId = queryParams.get('transactionId');

  const fetchTransactionData = async () => {
    if (!transactionReference && !transactionId) {
      setError('Referencia de transacción no válida');
      setLoading(false);
      return;
    }
    const url = new URL(`${config.tncApiUrl}/wompi/transaction`);
    if (transactionId) {
      url.searchParams.append('transactionId', transactionId);
    }
    if (transactionReference) {
      url.searchParams.append('reference', transactionReference);
    }
    try {
      const response = await fetch(url);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message || 'Error al obtener datos de la transacción'
        );
      }

      const result = await response.json();
      setResponseData(result.data); // Guardar los datos en el estado
    } catch (err) {
      console.error('Error fetching transaction data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactionData();
  }, [transactionReference]);

  useEffect(() => {
    fetchTransactionData();
  }, [transactionId]);

  const getReadablePaymentStatus = (status) => {
    return paymentStatusMapping[status] || paymentStatusMapping.DEFAULT;
  };

  const handleBackToHome = () => {
    window.location.href = '/login';
  };

  const login = () => {
    loginStrava();
  };

  const loginStrava = async () => {
    setLoading(true);
    try {
      const payload = {
        stravaId: responseData.user.stravaId,
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${config.tncApiUrl}/user/login`,
        requestOptions
      );

      if (!response.ok) {
        setLoading(false);
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error en el registro del evento');
      }

      const result = await response.json();
      setLoading(false);

      localStorage.setItem(
        'userData',
        JSON.stringify({ ...responseData, userToken: result.data.accessToken })
      );
      window.location.replace('/');
    } catch (error) {
      setLoading(false);
      console.error('Error en la solicitud de registro:', error);
      alert(error.message || 'Error al generar el pago');
    }
  };

  return (
    <div className='payment-success-page'>
      <Header signUp />
      <div className='container'>
        {loading ? (
          <div className='loading-container'>
            <CircularProgress />
            <Typography variant='h6'>
              Cargando información de la transacción...
            </Typography>
          </div>
        ) : error ? (
          <div className='error-container'>
            <Typography variant='h6' color='error'>
              {error}
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={handleBackToHome}
            >
              Volver al Inicio
            </Button>
          </div>
        ) : (
          <div className='transaction-summary'>
            <Typography variant='h4' className='title'>
              RESUMEN DE LA TRANSACCIÓN
            </Typography>

            <div className='summary-section'>
              <Typography variant='h6'>Estado del Pago:</Typography>
              <Typography
                variant='body1'
                className={`status ${responseData.userEventRegistration.paymentStatus}`}
              >
                {getReadablePaymentStatus(
                  responseData.userEventRegistration.paymentStatus
                )}
              </Typography>
            </div>

            <div className='summary-section'>
              <Typography variant='h6'>Detalles del Participante:</Typography>
              <div className='details-row'>
                <Typography variant='body1'>Nombre:</Typography>
                <Typography variant='body1'>
                  {responseData.user.name} {responseData.user.lastName}
                </Typography>
              </div>
              <div className='details-row'>
                <Typography variant='body1'>Email:</Typography>
                <Typography variant='body1'>
                  {responseData.user.email}
                </Typography>
              </div>
              <div className='details-row'>
                <Typography variant='body1'>Teléfono:</Typography>
                <Typography variant='body1'>
                  {responseData.user.phone}
                </Typography>
              </div>
            </div>

            <div className='summary-section'>
              <Typography variant='h6'>Detalles del Evento:</Typography>
              <div className='details-row'>
                <Typography variant='body1'>Segmento:</Typography>
                <Typography variant='body1'>
                  {responseData.userChallenge.segmentName} -{' '}
                  {responseData.userChallenge.segmentCity}
                </Typography>
              </div>
              <div className='details-row'>
                <Typography variant='body1'>Precio Final:</Typography>
                <Typography variant='body1'>
                  $
                  {responseData.userEventRegistration.finalPrice.toLocaleString()}
                </Typography>
              </div>
            </div>
            {responseData.userEventRegistration.paymentStatus === 'APPROVED' ? (
              <Button variant='contained' color='primary' onClick={login}>
                VER OTROS PARTICIPANTES
              </Button>
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={handleBackToHome}
              >
                Volver al Inicio
              </Button>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PaymentSuccessPage;
