import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';

import filter from '../../assets/images/filtros.svg';
import searchIcon from '../../assets/images/lupa.svg';
import { titleCase } from '../../utils/utils';
import './nav.scss';

const Nav = ({
  openFilter,
  segments,
  selectedSegment,
  selectedTab,
  setSelectedSegment,
  filterText,
  setFilterText,
}) => {
  const segment = segments.find(
    (segmentItem) => segmentItem.id === selectedSegment
  );
  return (
    <nav className='nav'>
      <Col xs={12}>
        <Row className='nav-row'>
          {setFilterText && (
            <Col xs={12} className='input-auto hide-desktop'>
              <div className='search-container'>
                <img
                  src={searchIcon}
                  alt='search-icon'
                  className='search-icon'
                />
                <input
                  value={filterText}
                  onChange={(event) => {
                    setFilterText(event.target.value);
                  }}
                />
              </div>
            </Col>
          )}
          <Col xs md={6} className='hide-mobile'>
            <Row className='segment-select-row'>
              {segment && (
                <Col>
                  <a
                    href={'https://www.strava.com/segments/' + segment.stravaId}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={
                        `/maps/${segment.shortCityName.toLowerCase()}.svg` ||
                        '/maps/bog.svg'
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/maps/bog.svg';
                      }}
                      alt='segment-map'
                      className='segment-map'
                    />
                  </a>
                </Col>
              )}

              <Col xs className='select-auto'>
                <FormControl className='segment-select-container'>
                  <Select
                    autoWidth
                    className='segment-select'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={selectedSegment}
                    onChange={(event) => {
                      setSelectedSegment(event.target.value);
                    }}
                  >
                    {segments.map((segmentItem) => {
                      return (
                        <MenuItem
                          key={`index${segmentItem.id}`}
                          value={segmentItem.id}
                        >
                          {selectedTab === 0 && (
                            <>{titleCase(segmentItem.cityName)}, </>
                          )}
                          {titleCase(segmentItem.name)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Col>
          {setFilterText && (
            <Col md className='input-auto hide-mobile'>
              <div className='search-container'>
                <img
                  src={searchIcon}
                  alt='search-icon'
                  className='search-icon'
                />
                <input
                  value={filterText}
                  onChange={(event) => {
                    setFilterText(event.target.value);
                  }}
                />
              </div>
            </Col>
          )}
          {openFilter && (
            <Col className='hide-mobile'>
              <div>
                <img
                  onClick={() => {
                    openFilter();
                  }}
                  src={filter}
                  alt='filter'
                  className='nav_filter'
                />
              </div>
            </Col>
          )}
          <Col xs={12} className='hide-desktop select-mobile-container'>
            {segment && (
              <div>
                <a
                  href={'https://www.strava.com/segments/' + segment.stravaId}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={`/maps/${segment.shortCityName.toLowerCase()}.svg`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/maps/bog.svg';
                    }}
                    alt='segment-map'
                    className='segment-map'
                  />
                </a>
              </div>
            )}
            <FormControl className='segment-select-container'>
              <Select
                className='segment-select'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedSegment}
                onChange={(event) => {
                  setSelectedSegment(event.target.value);
                }}
              >
                {segments.map((segmentItem) => {
                  return (
                    <MenuItem key={segmentItem.id} value={segmentItem.id}>
                      {selectedTab === 0 && (
                        <>{titleCase(segmentItem.cityName)}, </>
                      )}
                      {titleCase(segmentItem.name)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {openFilter && (
              <div>
                <img
                  onClick={() => {
                    openFilter();
                  }}
                  src={filter}
                  alt='filter'
                  className='nav_filter'
                />
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </nav>
  );
};

Nav.defaultProps = {
  selectedSegment: null,
  filterText: null,
  setFilterText: null,
  openFilter: null,
  selectedTab: 0,
};

Nav.propTypes = {
  openFilter: PropTypes.func,
  segments: PropTypes.array.isRequired,
  selectedSegment: PropTypes.string,
  selectedTab: PropTypes.number,
  setSelectedSegment: PropTypes.func.isRequired,
  filterText: PropTypes.string,
  setFilterText: PropTypes.func,
};

export default Nav;
