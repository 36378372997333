const config = {
  apiUrl: 'https://api.colombiachallenge.co',
  // tncApiUrl: 'https://1zhskoyq1j.execute-api.us-east-1.amazonaws.com/prod/v1',
  tncApiUrl: 'https://api.colombiachallenge.co/v1',
  eventId: '5',
  // apiUrl: 'http://127.0.0.1:3700',
  stravaApiUrl: 'https://www.strava.com/api/v3',
  //Strava Credentials
  clientID: '60533',
  clientSecret: 'a36d82afb757f3382b87616924161fb305ccf2bc',
  //aws data
  awsS3Url: 'https://colombia-resources.s3.us-east-2.amazonaws.com/',
  TNC_AWS_REGION: 'us-east-2',
  TNC_AWS_KEY_ID: 'AKIAZKUHXX4YMFOGA2G6',
  TNC_AWS_ACCESS_KEY: 'U+MYh2ujpCmn2J23/y0uP2yxUYj5CCnWWnfiuUTR',
  TNC_AWS_BUCKET: 'colombia-resources',
};

export default config;
