import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-flexbox-grid';
import config from '../../config';
import TableRankingIndividualItem from '../TableRankingIndividualItem';
import EmptyState from '../EmptyState';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';

const TableRankingIndividual = ({
  setShowLoading,
  setShowSecondLoading,
  individualFavorites,
  saveFavorite,
  deleteFavorite,
  selectedGenderFilter,
  changeIndividual,
  eighteenThirtyCategoryFilter,
  thirtyOneFortyFourCategoryFilter,
  fortyFiveFiftyNineCategoryFilter,
  sixtyPlusCategoryFilter,
  turboCategoryFilter,
  selectedSegment,
  filterText,
  isBike,
}) => {
  const [rakings, setRakings] = useState([]);
  const [rakingsFavorite, setRakingsFavorite] = useState([]);

  const getRanking = async (isBike) => {
    if (!selectedSegment) return;

    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('userData'));
    const headers = {
      Authorization: `Bearer ${athlete.userToken}`,
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      sportEventId: config.eventId,
      page: 1,
      limit: 1000,
    };

    params['sportType'] = isBike === 0 ? 'bike' : 'run';

    if (selectedGenderFilter) {
      params['gender'] = selectedGenderFilter;
    }

    if (eighteenThirtyCategoryFilter) {
      params['eighteenThirty'] = eighteenThirtyCategoryFilter;
    }
    if (thirtyOneFortyFourCategoryFilter) {
      params['thirtyOneFortyFour'] = thirtyOneFortyFourCategoryFilter;
    }
    if (fortyFiveFiftyNineCategoryFilter) {
      params['fortyFiveFiftyNine'] = fortyFiveFiftyNineCategoryFilter;
    }
    if (sixtyPlusCategoryFilter) {
      params['sixtyPlus'] = sixtyPlusCategoryFilter;
    }

    if (filterText) {
      params['search'] = filterText;
    }
    if (selectedSegment) {
      params['segmentId'] = selectedSegment;
    }

    params['guest'] = false;
    const url = new URL(`${config.tncApiUrl}/challenge/rankings`);
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const newRankings = await handleResponse(response);
      setRakings(newRankings.data.rankings);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getRankingFavorite = async (isBike) => {
    if (!selectedSegment) return;

    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('userData'));
    const headers = {
      Authorization: `Bearer ${athlete.userToken}`,
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      sportEventId: config.eventId,
      userId: athlete.user.id,
      page: 1,
      limit: 1000,
    };

    params['sportType'] = isBike === 0 ? 'bike' : 'run';

    if (selectedGenderFilter) {
      params['gender'] = selectedGenderFilter;
    }

    if (eighteenThirtyCategoryFilter) {
      params['eighteenThirty'] = eighteenThirtyCategoryFilter;
    }
    if (thirtyOneFortyFourCategoryFilter) {
      params['thirtyOneFortyFour'] = thirtyOneFortyFourCategoryFilter;
    }
    if (fortyFiveFiftyNineCategoryFilter) {
      params['fortyFiveFiftyNine'] = fortyFiveFiftyNineCategoryFilter;
    }
    if (sixtyPlusCategoryFilter) {
      params['sixtyPlus'] = sixtyPlusCategoryFilter;
    }

    if (filterText) {
      params['search'] = filterText;
    }
    if (selectedSegment) {
      params['segmentId'] = selectedSegment;
    }

    params['guest'] = false;
    const url = new URL(`${config.tncApiUrl}/challenge/favorites/rankings`);
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const newRankings = await handleResponse(response);
      setRakingsFavorite(newRankings.data.rankings);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (individualFavorites && selectedSegment) {
      getRankingFavorite(isBike);
    }
  }, [individualFavorites]);

  useEffect(() => {
    getRanking(isBike);
  }, [isBike]);

  useEffect(() => {
    if (changeIndividual && selectedSegment) {
      getRanking(isBike);
      getRankingFavorite(isBike);
    }
  }, [changeIndividual]);

  return (
    <Col xs={12}>
      <Row>
        {rakings.length > 0 &&
          rakingsFavorite.map((raking) => {
            return (
              <TableRankingIndividualItem
                userInfo={raking}
                key={`favorite${raking._id}`}
                darkMode
                firstRaking={rakings[0]}
                individualFavorites={individualFavorites}
                saveFavorite={saveFavorite}
                deleteFavorite={deleteFavorite}
                isBike={isBike}
              />
            );
          })}
        {rakings.length === 0 && <EmptyState />}
        {rakings.map((raking) => {
          return (
            <TableRankingIndividualItem
              userInfo={raking}
              key={`individual${raking._id}`}
              individualFavorites={individualFavorites}
              saveFavorite={saveFavorite}
              deleteFavorite={deleteFavorite}
              firstRaking={rakings[0]}
              isBike={isBike}
            />
          );
        })}
      </Row>
    </Col>
  );
};

TableRankingIndividual.propTypes = {
  isBike: PropTypes.number.isRequired,
  setShowLoading: PropTypes.func.isRequired,
  setShowSecondLoading: PropTypes.func.isRequired,
  individualFavorites: PropTypes.array.isRequired,
  saveFavorite: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
  selectedGenderFilter: PropTypes.string,
  eighteenThirtyCategoryFilter: PropTypes.string,
  thirtyOneFortyFourCategoryFilter: PropTypes.string,
  fortyFiveFiftyNineCategoryFilter: PropTypes.string,
  sixtyPlusCategoryFilter: PropTypes.string,
  turboCategoryFilter: PropTypes.string,
  selectedSegment: PropTypes.string,
  filterText: PropTypes.string,
  changeIndividual: PropTypes.number,
};

TableRankingIndividual.defaultProps = {
  eighteenThirtyCategoryFilter: null,
  thirtyOneFortyFourCategoryFilter: null,
  fortyFiveFiftyNineCategoryFilter: null,
  sixtyPlusCategoryFilter: null,
  turboCategoryFilter: null,
  selectedGenderFilter: null,
  changeIndividual: null,
  selectedSegment: null,
  filterText: null,
};

export default TableRankingIndividual;
