import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/images/logo-header-white.png';
import addlogo from '../../assets/images/addlogo.svg';
import editicon from '../../assets/images/editicon.svg';
import defaultFoto from '../../assets/images/foto.jpeg';
// import stravaLogo from '../../assets/images/strava.svg';
import './header.scss';

const Header = ({ resetFilters, showReset, signUp, history }) => {
  const [currentAthlete, setCurrentAthlete] = useState({});
  const [currentName, setCurrentName] = useState();

  useEffect(() => {
    const athlete = JSON.parse(localStorage.getItem('userData'));
    if (athlete && athlete.userChallenge) {
      setCurrentAthlete(athlete.userChallenge);
      const newCurrentName = athlete.userChallenge.firstName.split(' ')[0];
      setCurrentName(newCurrentName);
    }
  }, []);

  const goEdit = () => {
    history.push(`/edit`);
  };

  const goHome = () => {
    history.push(`/`);
  };

  const openIG = () => {
    window.open('https://www.instagram.com/tnc.community/', '_blank');
  };

  return (
    <header className='header'>
      {!signUp && (
        <div className='edit-info-container'>
          <div
            className={`header_profile-photo-container ${
              currentAthlete.isGuest ? '' : 'hover'
            }`}
            onClick={() => {
              if (!currentAthlete.isGuest) {
                goEdit();
              }
            }}
          >
            <img
              src={currentAthlete.photoUrl || defaultFoto}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultFoto;
              }}
              alt='profile'
              className='header_profile-photo'
            />
            {!currentAthlete.isGuest && (
              <img src={editicon} alt='edit icon' className='edit_icon' />
            )}
          </div>
          {currentName && (
            <div className='name-container hide-mobile'>
              Hola,
              <br /> {currentName}
            </div>
          )}
        </div>
      )}

      <div className='header_container_logo'>
        <img src={logo} alt='logo' className='header_logo' onClick={goHome} />
      </div>
      {showReset && !signUp ? (
        <div className='reset-filters' onClick={resetFilters}>
          Limpiar Filtros
        </div>
      ) : (
        <div className='reset-filters' onClick={openIG}>
          <div className='go-IG'>
            <img src={addlogo} alt='add logo' className='add-logo' />
          </div>
        </div>
      )}
      {/* <div className='add-button-container'>
        <img
          src={stravaLogo}
          alt='strava logo'
          className='header_strava_logo'
        />
        <div className='add-button'>+</div>
      </div> */}
    </header>
  );
};

Header.propTypes = {
  resetFilters: PropTypes.func,
  showReset: PropTypes.bool,
  signUp: PropTypes.bool,
};

Header.defaultProps = {
  signUp: false,
  resetFilters: null,
  showReset: null,
};

export default withRouter(Header);
