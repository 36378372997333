export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const GENDERS = [
  { value: 'male', label: 'Hombre' },
  { value: 'female', label: 'Mujer' },
];

export const SIZES = [
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: 'XXL', label: 'XXL' },
  { value: '3XL', label: '3XL' },
  { value: '4XL', label: '4XL' },
];

export const BIKES = [
  'AGR',
  'ARGON',
  'ARGON18',
  'BASSO',
  'BIANCHI',
  'BLUE',
  'BMC',
  'BTWIN',
  'CANNONDALE',
  'CANYON',
  'CEEPO',
  'CERVELO',
  'COLNAGO',
  'CUBE',
  'DEROSA',
  'FACTOR',
  'FELT',
  'FOCUS',
  'FUJI',
  'GIANT',
  'GUERCIOTTI',
  'GW',
  'KESTREL',
  'KTM',
  'LAPIERRE',
  'LIV',
  'LOOK',
  'MERIDA',
  'ORBEA',
  'PINARELLO',
  'QUINTANAROO',
  'RALEIGH',
  'RIDLEY',
  'ROSSETTI',
  'SCOTT',
  'SPECIALIZED',
  'STL',
  'TREK',
  'URANIUM',
  'WILIER',
];

export const SHOES = [
  'Nike',
  'Adidas',
  'Asics',
  'Brooks',
  'New Balance',
  'Saucony',
  'Hoka',
  'Otra',
];
