import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

import { Row, Col } from 'react-flexbox-grid';
import moment from 'moment';
import Footer from '../../components/Footer';
import { titleCase } from '../../utils/utils';

import './editAthlete.scss';
import Loading from '../../components/loading';
import { SHOES, BIKES, GENDERS, SIZES } from '../../utils/utils';
import config from '../../config';
import {
  handleResponse,
  handleStravaResponse,
  serializeQuery,
} from '../../utils/handleResponse';
import camera from '../../assets/images/camara.svg';
import threearrow from '../../assets/images/3arrow.svg';
import Header from '../Header';

const EditAthlete = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [gender, setGender] = useState('');
  const [sizeShirt, setSizeShirt] = useState('');
  const [bicycleBrand, setBicycleBrand] = useState('');
  const [shoesBrand, setShoesBrand] = useState('');
  const [initialSegmentId, setInitialSegmentId] = useState('');
  const [segmentId, setSegmentId] = useState('');
  const [currentAthlete, setCurrentAthlete] = useState();
  const [runSegments, setRunSegments] = useState([]);
  const [bikeSegments, setBikeSegments] = useState([]);
  const [segments, setSegments] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [prTime, setPrTime] = useState(null);
  const [prDate, setPrDate] = useState(null);

  const today = moment();
  const maxSegmentDate = moment('2025-06-17', 'YYYY-MM-DD');
  const maxTshirtDate = moment('2025-06-01', 'YYYY-MM-DD');
  const disabledSegment = maxSegmentDate < today;

  const disabledTshirt = maxTshirtDate < today;

  useEffect(() => {
    if (currentAthlete) {
      fetchRunSegments();
      fetchBikeSegments();
      setFirstName(currentAthlete.firstName);
      setLastName(currentAthlete.lastName);
      setEmail(currentAthlete.email);
      setAddress(currentAthlete.address);
      setGender(currentAthlete.gender);
      setSizeShirt(currentAthlete.sizeShirt);
      setSegmentId(currentAthlete.segmentId);
      setInitialSegmentId(currentAthlete.segmentId);
      setBicycleBrand(currentAthlete.bicycleBrand);
      setShoesBrand(currentAthlete.shoesBrand);
      getAccesToken();
    }
  }, [currentAthlete]);

  useEffect(() => {
    const athlete = JSON.parse(localStorage.getItem('userData'));
    if (athlete && athlete.userChallenge) {
      setCurrentAthlete(athlete.userChallenge);
    }
  }, []);

  useEffect(() => {
    if (segmentId && accessToken) {
      const selectedSegment = segments.find(
        (segment) => segment.id === segmentId
      );
      if (
        currentAthlete.sportType === 'bike' &&
        selectedSegment?.stravaId &&
        accessToken
      ) {
        getStravaSegmentEfforts(selectedSegment.stravaId);
      } else {
        setPrDate(null);
        setPrTime(null);
      }
    }
  }, [segmentId]);

  useEffect(() => {
    if (currentAthlete && currentAthlete.sportType) {
      if (currentAthlete.sportType === 'run') {
        setSegments(runSegments);
      } else {
        setSegments(bikeSegments);
      }
      setSegmentId(currentAthlete.segmentId);
    }
  }, [runSegments, bikeSegments]);

  const getAccesToken = async () => {
    setShowLoading(true);
    const url = new URL(`https://www.strava.com/oauth/token`);
    const params = {
      client_id: config.clientID,
      client_secret: config.clientSecret,
      refresh_token: currentAthlete.refreshToken,
      grant_type: 'refresh_token',
    };
    const requestOptions = {
      method: 'POST',
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      const refreshToken = body.refresh_token;
      setAccessToken(body.access_token);
      updateAthlete({
        refreshToken,
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const getStravaSegmentEfforts = async (segmentId) => {
    setShowLoading(true);
    const url = new URL(
      `${config.stravaApiUrl}/segment_efforts?segment_id=${segmentId}`
    );
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const stravaAthleteEfforts = await handleStravaResponse(response);
      // const stravaAthleteEffortFound = stravaAthleteEfforts.find(
      //   (stravaAthleteEffort) => `${stravaAthleteEffort.id}`.length === 11
      // );
      if (stravaAthleteEfforts && stravaAthleteEfforts.length > 0) {
        getStravaSegmentEffort(stravaAthleteEfforts[0].id);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getStravaSegmentEffort = async (effortId) => {
    setShowLoading(true);
    const url = new URL(
      `${config.stravaApiUrl}/segment_efforts/${effortId.toString()}`
    );
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);

    setShowLoading(false);
    try {
      const stravaAthleteEffort = await handleResponse(response);
      setPrTime(stravaAthleteEffort.athlete_segment_stats.pr_elapsed_time);
      setPrDate(stravaAthleteEffort.athlete_segment_stats.pr_date);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchSegments = async (sportType) => {
    try {
      const response = await fetch(
        `${config.tncApiUrl}/segments?sportEventId=${config.eventId}&sportType=${sportType}`
      );
      const data = await response.json();
      if (data && data.data && data.data.segments) {
        return data.data.segments;
      }
      return [];
    } catch (error) {
      console.error(`Error fetching ${sportType} segments:`, error);
      return [];
    }
  };

  const fetchRunSegments = async () => {
    const segments = await fetchSegments('run');
    setRunSegments(segments);
  };

  const fetchBikeSegments = async () => {
    const segments = await fetchSegments('bike');
    setBikeSegments(segments);
  };

  // use current access token to call all activities
  // const getStravaAthlete = async () => {
  //   setShowLoading(true);
  //   const url = new URL(`${config.stravaApiUrl}/athlete`);
  //   const headers = {
  //     Authorization: `Bearer ${authBearer}`,
  //   };
  //   const requestOptions = {
  //     method: 'GET',
  //     headers,
  //   };
  //   const response = await fetch(url, requestOptions);
  //   setShowLoading(false);
  //   try {
  //     const stravaAthlete = await handleResponse(response);
  //     setStravaUser(stravaAthlete);
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  const updateAthlete = async (bodyToken = null) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('userData'));
    const url = new URL(`${config.tncApiUrl}/event/colombia-challenge`);
    url.searchParams.append('userId', athlete.user.id);
    url.searchParams.append('eventId', config.eventId);
    const headers = {
      Authorization: `Bearer ${athlete.userToken}`,
      'Content-Type': 'application/json',
    };
    const segment = segments.find(
      (segmentItem) => segmentItem.id === segmentId
    );
    const body = bodyToken || {
      sizeShirt,
      segmentName: segment.name,
      segmentCity: segment.cityName,
      segmentId: segment.id,
      segmentStravaId: segment.stravaId,
      bicycleBrand,
      shoesBrand,
      address,
    };

    if (initialSegmentId !== segmentId) {
      body['prTime'] = prTime || null;
      body['prDate'] = prDate || null;
    }

    const requestOptions = {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const updatedAthlete = await handleResponse(response);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          user: athlete.user,
          userChallenge: updatedAthlete.data.userChallenge,
          userEventRegistration: athlete.userEventRegistration,
          userToken: athlete.userToken,
        })
      );
      if (!bodyToken) {
        window.location.replace('/ranking');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const save = () => {
    updateAthlete();
  };

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  const logOut = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('access');
    window.location.replace('/ranking');
  };

  return (
    <div className='edit-athlete'>
      {showLoading && <Loading />}
      <Header editAthlete />
      <div className='photo-section'>
        <div className='photo-background' />
        <div className='photo-container'>
          <div className='photo-area'>
            <img src={camera} alt='camera' className='upload-photo' />
            {currentAthlete && (
              <img
                src={currentAthlete.photoUrl}
                alt='user'
                className='user-photo'
              />
            )}
          </div>
        </div>
      </div>
      <Col xsOffset={1} xs={10} md={6} mdOffset={3}>
        <Row>
          <Col xs={12}>
            <div className='edit-title-container'>
              <div className='edit-title'>
                INFORMACIÓN
                <br /> PERSONAL
              </div>
              <img className='three-arrow' src={threearrow} alt='logo' />
            </div>
          </Col>
        </Row>
      </Col>
      <Col xsOffset={1} xs={10} md={6} mdOffset={3}>
        <Row>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setFirstName)}
                value={firstName}
                id='firstName'
                disabled
                label='Nombre'
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setLastName)}
                value={lastName}
                id='lastName'
                disabled
                label='Apellido'
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setEmail)}
                value={email}
                disabled
                id='email'
                label='Email'
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='gender-label'>Genero</InputLabel>
              <Select
                labelId='gender-label'
                id='gender'
                disabled
                onChange={changeInput(setGender)}
                value={gender}
              >
                {GENDERS.map((selectGender) => {
                  return (
                    <MenuItem
                      key={selectGender.value}
                      value={selectGender.value}
                    >
                      {selectGender.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='sizeShirt-label'>Talla Jersey</InputLabel>
              <Select
                labelId='sizeShirt-label'
                id='sizeShirt'
                onChange={changeInput(setSizeShirt)}
                disabled={disabledTshirt}
                value={sizeShirt}
              >
                {SIZES.map((selectSizeShirt) => {
                  return (
                    <MenuItem
                      key={selectSizeShirt.value}
                      value={selectSizeShirt.value}
                    >
                      {selectSizeShirt.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Cambios hasta 31 de Mayo</FormHelperText>
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <InputLabel id='segmentId-label'>Segmento</InputLabel>
              <Select
                labelId='segmentId-label'
                id='segmentId'
                onChange={changeInput(setSegmentId)}
                value={segmentId}
                disabled={disabledSegment}
              >
                {segments.map((segment) => {
                  return (
                    <MenuItem key={segment.id} value={segment.id}>
                      {currentAthlete?.sportType === 'bike' ? (
                        <>
                          {segment.name}, {segment.cityName}
                        </>
                      ) : (
                        <>{segment.name}</>
                      )}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Cambios hasta 16 de Junio</FormHelperText>
            </FormControl>
          </Col>
          {currentAthlete && currentAthlete.sportType === 'bike' && (
            <Col xs={12} md={6} className='field-container'>
              <FormControl fullWidth>
                <InputLabel id='bicycleBrand-label'>
                  Marca de bicicleta
                </InputLabel>
                <Select
                  labelId='bicycleBrand-label'
                  id='bicycleBrand'
                  onChange={changeInput(setBicycleBrand)}
                  value={bicycleBrand}
                >
                  {BIKES.map((selectBike) => {
                    return (
                      <MenuItem key={selectBike} value={selectBike}>
                        {selectBike}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Col>
          )}
          {currentAthlete && currentAthlete.sportType === 'run' && (
            <Col xs={12} md={6} className='field-container'>
              <FormControl fullWidth>
                <InputLabel id='shoesBrand-label'>Marca de Zapatos</InputLabel>
                <Select
                  labelId='shoesBrand-label'
                  id='shoesBrand'
                  onChange={changeInput(setShoesBrand)}
                  value={shoesBrand}
                >
                  {SHOES.map((selectShoes) => {
                    return (
                      <MenuItem key={selectShoes} value={selectShoes}>
                        {selectShoes}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Col>
          )}

          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setAddress)}
                value={address}
                id='address'
                label='Dirección'
                helperText='Cambios hasta 16 de Junio'
                disabled={disabledSegment}
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} mdOffset={3}>
            <button
              onClick={save}
              className='submit-button'
              disabled={
                !(
                  !!firstName &&
                  !!lastName &&
                  !!email &&
                  !!gender &&
                  !!sizeShirt &&
                  !!address
                )
              }
            >
              Guardar
            </button>
          </Col>
          <Col xs={12} md={6} mdOffset={3}>
            <button onClick={logOut} className='submit-button log-out'>
              Log Out
            </button>
          </Col>
        </Row>
      </Col>
      <Footer />
    </div>
  );
};

export default EditAthlete;
